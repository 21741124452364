export default {
  data () {
    return {
      dataMixinCallReportInfoFields: [
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Team',
          key: 'team_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Project',
          key: 'project_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Player ID',
          key: 'player_id',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Comment',
          key: 'issue_comment',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Created At',
          key: 'created_at',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        }
      ]
    };
  },
  methods: {
    // onHover (item) {
    //   item.actions = true;
    // },
    // onUnHover (item) {
    //   item.actions = false;
    // }
  }
};
