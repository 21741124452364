<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    responsive
    size="xl"
    title="Platforms Usage. Slowness."
    modal-class="modal-issues-report"
    @close="onCloseModal">
    <div>
      <div class="table-wrapper">
        <b-table
          class="table-issues-report"
          striped
          hover
          show-empty
          sticky-header
          :fields="dataMixinCallReportInfoFields"
          :items="objIssues.calls"
          no-local-sorting>
          <template #cell(created_at)="data">
            {{ data.item.created_at | dateFilter }}
          </template>
        </b-table>
      </div>
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataAddIcon"
        type="success"
        @click="onCloseModal()">
        Close
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { /* BFormTextarea, */ BModal, BTable } from 'bootstrap-vue';
import CallButton from '../CallButton';
// import CallInput from '../CallInput';
import { minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { ISSUES_GET_CATEGORIES, ISSUES_UPDATE, TEAMS_GET_ALL } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import selectAllTeams from '@/mixins/select/selectAllTeams';
import selectIssuesCategories from '@/mixins/select/selectIssuesCategories';
import callReportInfoTable from '@/mixins/table/callReportInfoTable';

export default {
  name: 'CallModalUpdateIssues',
  components: {
    // CallInput,
    CallButton,
    BModal,
    BTable
    // BFormTextarea
  },
  mixins: [validationMixin, iconsModal, selectAllTeams, selectIssuesCategories, callReportInfoTable],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    objIssues: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        category_id: {},
        team_ids: [],
        name: null,
        description: null
      }
    };
  },
  validations: {
    dataForm: {
      name: {
        minLength: minLength(3),
        required
      },
      description: {
        minLength: minLength(3),
        required
      },
      category_id: {
        required
      },
      team_ids: {
        required
      }
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        const [{ payload: { list = [] } }, issuesCategories = []] = await Promise.all([
          this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
            params: {
              short: true
            }
          }),
          this.$store.dispatch(`issues/${ISSUES_GET_CATEGORIES}`, {})
        ]);
        this.dataSelectOptionsTeam = list;
        this.dataSelectOptionsIssuesCategories = issuesCategories;

        this.dataForm.description = this.objIssues.description;
        this.dataForm.name = this.objIssues.name;
        this.dataForm.category_id = issuesCategories.find(item => item.id === this.objIssues.category_id);
        this.dataForm.team_ids = list.filter(item => {
          return this.objIssues.teams.some(team => team.id === item.id);
        });
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickAdd () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`issues/${ISSUES_UPDATE}`, {
          id: this.objIssues.id,
          params: {
            ...this.dataForm,
            team_ids: this.dataForm.team_ids.map(item => item.id),
            category_id: this.dataForm.category_id?.id ?? null
          },
          context: this
        });
        this.onCloseModal();
        this.$emit('on-update');
        this.$Notify({
          title: 'Issues',
          message: 'Issues successfully updated',
          type: 'success'
        });
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
