<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Update Issue Category"
    @close="onCloseModal">
    <div>
      <call-input
        v-model="$v.dataForm.name.$model"
        type-input="text"
        :valide="$v.dataForm.name.$error"
        float-name-label="Category Name" />
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataRefreshIcon"
        :disabled="$v.$invalid"
        type="success"
        @click="clickUpdate()">
        Update Category
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import CallInput from '../CallInput';
import { minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { ISSUES_UPDATE_CATEGORIES } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';

export default {
  name: 'CallModalUpdateIssuesCategories',
  components: {
    CallInput,
    CallButton,
    BModal
  },
  mixins: [validationMixin, iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    objCategory: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        name: null
      }
    };
  },
  validations: {
    dataForm: {
      name: {
        minLength: minLength(3),
        required
      }
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        this.dataForm.name = this.objCategory.name;
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickUpdate () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`issues/${ISSUES_UPDATE_CATEGORIES}`, {
          params: {
            name: this.dataForm.name
          },
          id: this.objCategory.id,
          context: this
        });
        this.onCloseModal();
        this.$emit('on-update');
        this.$Notify({
          title: 'Issues',
          message: 'Category successfully updated',
          type: 'success'
        });
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
