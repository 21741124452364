<template>
  <div class="home">
    <div class="filter-wrapper">
      <div class="link-wrapper">
        <router-link
          :to="{ name: 'CallsRecords'}">
          Records
        </router-link>
        <router-link :to="{ name: 'CallsLogs'}">
          Logs
        </router-link>
        <router-link
          v-if="computedIsAdmin"
          :to="{ name: 'CallsIssues'}">
          Issues
        </router-link>
        <router-link
          :to="{ name: 'CallsReport'}">
          Issues Report
        </router-link>

        <v-select
          v-model="callsPeriodValue"
          :options="periodList"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Last Days"
          :filterable="false"
          :searchable="false"
          :class="['call-select call-select-filter', callsPeriodValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectIssuesCategoriesValue"
          :options="dataListIssuesCategories"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Categories"
          :class="['call-select call-select-filter', dataSelectIssuesCategoriesValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Teams"
          :class="['call-select call-select-filter', dataSelectTeamsValue ? 'call-select--value' : null]"
          @input="onSelectTeams">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectIssueValue"
          :options="dataSelectOptionsIssues"
          label="name"
          style="height: 38px; max-width: 200px;"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Issues"
          :class="['call-select call-select-filter', dataSelectIssueValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Issue
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <call-pagination
          :page-value="50"
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        class="table-issues-report"
        striped
        hover
        show-empty
        no-local-sorting
        :fields="dataMixinCallReportFields"
        :items="dataCallIssuesList"
        @sort-changed="onSortTabel"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
        <template #cell(description)="data">
          <call-tooltip
            placement="bottom"
            :content="data.item.description">
            <span>{{ data.item.description }}</span>
          </call-tooltip>
        </template>
        <template #cell(teams)="data">
          <call-tooltip
            placement="bottom"
            :content="data.item.teams.map(item => item.name).join('\n')">
            <span>{{ data.item.teams.length }} Teams</span>
          </call-tooltip>
        </template>
        <template #cell(actions)="data">
          <call-table-issues-report-actions
            v-show="data.item.actions"
            @on-action="() => onActions(data.item, 'view')" />
        </template>
      </b-table>
    </div>
    <call-modal-add-issues-categories
      v-model="dataShowIssuesCategories"
      @on-update="$_ajaxIssuesCategories" />
    <call-modal-add-issues
      v-model="dataShowIssues"
      @on-update="$_ajax" />
    <call-modal-report-issues
      v-model="dataShowUpdateIssues"
      :obj-issues="dataPropsUpdateIssues"
      @on-update="$_ajax" />
    <call-modal-update-issues-categories
      v-model="dataShowIssuesUpdateCategories"
      :obj-category="dataPropsItemIssuesCategories"
      @on-update="$_ajaxIssuesCategories" />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { ISSUES_GET, ISSUES_GET_CATEGORIES, TEAMS_GET_ALL } from '../../../store/types/actions.types';
import helper from '../../../service/helper';
import CallPagination from '@/components/desktop/CallPagination';
import { faCheck, faClock, faComments, faList, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
// import CallButton from '../../../components/common/CallButton';
// import Popover from 'vant/lib/popover';
import 'vant/lib/popover/style';
// import CallUiCategories from '@/components/common/ui_categories/CallUiCategories';
import selectAllTeams from '@/mixins/select/selectAllTeams';
import callReportTable from '@/mixins/table/callReportTable';
import CallModalAddIssuesCategories from '@/components/common/modals/CallAddIssuesCategories';
import CallModalAddIssues from '@/components/common/modals/CallAddIssues';
import CallModalUpdateIssuesCategories from '@/components/common/modals/CallUpdateIssuesCategories';
import CallTableIssuesReportActions from '@/components/desktop/CallTableIssuesReportActions';
import CallModalReportIssues from '@/components/common/modals/CallReportIssues';
import selectIssuesCategories from '@/mixins/select/selectIssuesCategories';

export default {
  name: 'PageSms',
  components: {
    CallModalReportIssues,
    CallTableIssuesReportActions,
    CallModalUpdateIssuesCategories,
    CallModalAddIssues,
    CallModalAddIssuesCategories,
    // CallUiCategories,
    CallPagination,
    BTable
    // Popover,
    // CallButton
  },
  mixins: [
    callReportTable,
    selectAllTeams,
    selectIssuesCategories
  ],
  data () {
    const issuesCategories = {
      dataShowPopover: false,
      dataShowIssuesCategories: false,
      dataShowIssuesUpdateCategories: false,
      dataPropsItemIssuesCategories: {},
      dataListIssuesCategories: []
    };

    const issues = {
      dataSelectIssueValue: null,
      dataSelectOptionsIssues: []
    };

    return {
      dataIconCheck: faCheck,
      dataIconError: faTimes,
      dataIconClock: faClock,
      dataIconComment: faComments,
      dataIconList: faList,
      dataAddIcon: faPlus,
      dataObjPagination: {},
      dataCallIssuesList: [],
      dataDesc: null,
      dataOrderBy: null,
      callsPeriodValueDefault: { id: 'day', name: 'Today' },
      callsPeriodValue: { id: 'day', name: 'Today' },
      dataShowIssues: false,
      dataShowUpdateIssues: false,
      dataPropsUpdateIssues: {},
      ...issuesCategories,
      ...issues
    };
  },
  computed: {
    ...mapGetters({
      computedIsOperator: 'auth/isOperator',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser',
      computedIsAdmin: 'auth/isAdmin'
    }),

    periodList () {
      return [
        {
          id: 'day',
          name: 'Today'
        },
        {
          id: 'last_day',
          name: 'Yesterday'
        },
        {
          id: 'week',
          name: 'This Week'
        },
        {
          id: 'last_week',
          name: 'Last Week'
        },
        {
          id: 'month',
          name: 'This Month'
        },
        {
          id: 'last_month',
          name: 'Last Month'
        }
      ];
    }
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = 50;

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'desc',
            'orderBy',
            'limit',
            'page',
            'period',
            'teamId',
            'operator_ids',
            'categoryId',
            'limit',
            'page',
            'search',
            'statuses',
            'calls_period',
            'issuesId'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              console.log(options);
              switch (_key) {
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
                case 'teamId':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'calls_period':
                  this.callsPeriodValue = options[_key];
                  break;
                case 'issuesId':
                  this.dataSelectIssueValue = options[_key];
                  break;
                case 'categoryId':
                  this.dataSelectIssuesCategoriesValue = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({
        desc = this.dataDesc,
        orderBy = this.dataOrderBy,
        teamId = this.dataSelectTeamsValue,
        categoryId = this.dataSelectIssuesCategoriesValue,
        limit = null,
        page = null,
        // calls_period = this.callsPeriodValue,
        issuesId = this.dataSelectIssueValue,
        reset = false
      } = options) => {
        if (!this.callsPeriodValue) {
          this.callsPeriodValue = this.callsPeriodValueDefault;
        }
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const [{ payload: { list = [] }, misc: { paging = {} } = {} }] = await Promise.all([
          this.$store.dispatch(`issues/${ISSUES_GET}`, {
            params: {
              is_report: true,
              sort_dir: desc ? 'desc' : 'asc',
              sort_column: orderBy,
              limit: _limit,
              page: _page,
              team_ids: [teamId?.id],
              category_ids: [categoryId?.id],
              calls_period: this.callsPeriodValue?.id,
              issue_ids: [issuesId?.id]
            }
          })
        ]);

        this.dataObjPagination = paging;
        this.dataCallIssuesList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              desc,
              limit: _limit,
              page: _page,
              teamId,
              categoryId,
              calls_period: this.callsPeriodValue,
              issuesId
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    this.$_ajaxIssues = (() => {
      const _cacheIssues = {};

      return async (teamId = null, categoryId = null) => {
        const key = `${String(categoryId ?? 0)},${String(teamId ?? 0)}`;
        if (Array.isArray(_cacheIssues[key])) {
          this.dataSelectOptionsIssues = cloneDeep(_cacheIssues[key]);
          return;
        }

        try {
          const { payload: { list = [] } } = await this.$store.dispatch(`issues/${ISSUES_GET}`, {
            params: {
              // category_ids: [categoryId],
              team_ids: [teamId]
            }
          });

          _cacheIssues[key] = list;
          this.dataSelectOptionsIssues = cloneDeep(list);
        } catch (e) {
          console.log(e);
        }
      };
    })();

    const [{ payload: { list: listTeam = [] } }] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajax(),
      this.$_ajaxIssuesCategories(),
      this.$_ajaxIssues()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    onSelectTeams () {
      // this.dataSelectOperatorValue = null;
      this.dataSelectIssueValue = null;
      this.$_ajaxIssues(this.dataSelectTeamsValue?.id);
      this.$_ajax({ reset: true });
    },

    async $_ajaxIssuesCategories () {
      try {
        this.dataListIssuesCategories = await this.$store.dispatch(`issues/${ISSUES_GET_CATEGORIES}`, {});
      } catch (e) {
        console.log(e);
      }
    },
    clickShowAddIssues () {
      this.dataShowIssues = true;
    },
    onSortTabel ({ sortDesc, sortBy }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    async onActions (item, action) {
      switch (action) {
        case 'view':
          this.dataPropsUpdateIssues = item;
          this.dataShowUpdateIssues = true;
          break;
      }
    }
  }
};
</script>
<style scoped lang="sass">
.van-popover__wrapper
  margin-right: 8px
  .call-button
    font-family: "cera-regular"
</style>
